<template>
  <div class="z-login-page">
    <div class="login-page-bg" :style="{backgroundImage: 'url(' + picArray.bgPic + ')' }">
    </div>
    <div class="login-page-container">
      <div class="login-logo-conatiner">
        <img :src="picArray.tgLogo">
      </div>
      <div class="login-form-container">
        <div class="login-form-wrap">
          <div class="login-form-box" v-loading="loading">
            <div class="login-form-box-header">
              <img :src="picArray.userLogin">
            </div>
            <div class="login-form-box-body">
              <div class="logon-error" v-show="hasError">
                <div><img :src="picArray.erroIcon"></div>
                <p>默认账号:admin,密码:admin</p>
              </div>
              <form class="login-form">
                <div class="login-form-input">
                  <label for="userName" class="login-form-label">
                    <span><img :src="picArray.userIcon"></span>
                  </label>
                  <input type="text" placeholder="请输入账号" v-model="loginForm.userName" name="userName" id="userName" @keyup.enter="handleLogin()" />
                </div>
                <div class="login-form-input">
                  <label for="password" class="login-form-label">
                    <span><img :src="picArray.lockedIcon"></span>
                  </label>
                  <input type="password" placeholder="请输入密码" name="password" id="password" v-model="loginForm.password" @keyup.enter="handleLogin()" />
                </div>
                <div class="login-form-button" @click="handleLogin()">
                  <a href="javascript:;">登录</a>
                </div>
              </form>
            </div>
            <div class="login-form-header"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bgPic from '@/assets/loginPage/bg.png'
import tgLogo from '@/assets/loginPage/tgLogo1.png'
import userLogin from '@/assets/loginPage/userLogin.png'
import lockedIcon from '@/assets/loginPage/Locked.png'
import userIcon from '@/assets/loginPage/user_logo.png'
import erroIcon from '@/assets/loginPage/warning.png'
import { validateLogin } from '@/config/validateRules'
import { mapGetters } from 'vuex'
const userInfo = {
  password: 'admin123',
  userName: 'admin'
}
export default {
  name: 'login',
  data() {
    return {
      picArray: {
        erroIcon: erroIcon,
        bgPic: bgPic,
        tgLogo: tgLogo,
        userLogin: userLogin,
        lockedIcon: lockedIcon,
        userIcon: userIcon
      },
      loading: false,
      hasError: false, // 错误提示
      reSubmit: false, // 重复提交
      validateRules: validateLogin,
      loginForm: {
        password: '',
        userName: ''
      }
    }
  },
  computed: {
    ...mapGetters(['roleName'])
  },
  methods: {
    handleLogin() {
      this.reSubmit = true
      this.loading = true
      const formData = new FormData()
      formData.append('userName', this.loginForm.userName)
      formData.append('password', this.loginForm.password)
      this.$store.dispatch('login', formData)
        .then(() => {
          this.$nextTick(() => {
            if(this.roleName === 'ROLE_SUPER_ADMIN'){
              this.$router.push({ path: '/policyManage/policyList' })
            } else {
              this.$router.push({ path: '/supplierManage/supplierList'})
            }
          })
        }, () => {
          this.$message('登录失败请于管理员联系')
        })
        .finally(() => {
          this.loading = false
          this.reSubmit = false
        })
    }
  },
  created () {
    this.$store.dispatch('clearZtoSession')
    this.$store.dispatch('delAllViews')
  }
}
</script>
<style lang="scss" scoped>
@media (min-height: 620px) {
  .z-login-page .login-page-container {
    top: 20% !important;
  }
}
.z-login-page {
  .login-page-container {
    .login-form-container {
      .login-form-wrap {
        margin: 0 auto;
        width: 100%;
        position: relative;
        height: 405px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 10px;
        .login-form-box {
          background-color: #ffffff;
          border-radius: 10px;
          width: 465px;
          height: 385px;
          top: 10px;
          left: 10px;
          position: absolute;
          .login-form-box-body {
            .login-form {
              .login-form-label {
                left: 0;
                top: 1px;
                position: absolute;
                width: 58px;
                height: 52px;
                line-height: 52px;
                text-align: center;
              }
              input {
                &:focus {
                  border-color: rgb(104, 182, 255);
                  border-style: solid;
                  background-color: rgb(255, 255, 255);
                  box-shadow: 0px 0px 4px 0px rgba(1, 126, 244, 0.45);
                }
                height: 52px;
                box-sizing: border-box;
                width: 100%;
                font-size: 15px;
                padding: 15px 15px 15px 58px;
                border: 1px solid #ddd;
                border-radius: 0;
              }
              .login-form-button,
              .login-form-input {
                position: relative;
                margin-top: 20px;
              }
              .login-form-button {
                a {
                  color: #fff;
                }
                &:hover {
                  background-color: rgba(5, 117, 255, 0.8);
                }
                cursor: pointer;
                height: 52px;
                line-height: 52px;
                background-color: #0575ff;
                font-size: 20px;
              }
            }
            .logon-error {
              div {
                padding-right: 6px;
                float: left;
                width: 20px;
                height: 20px;
              }
              p {
                white-space: normal;
                word-wrap: break-word;
                margin: 0;
                float: left;
                width: 313px;
              }
              font-size: 16px;
              color: #ff4242;
              text-align: left;
              margin-top: 5px;
            }
            & :after {
              display: block;
              clear: both;
              content: '';
              visibility: hidden;
              height: 0;
            }
            box-sizing: border-box;
            width: 100%;
            padding: 0 53px;
            margin-bottom: 5px;
          }
          .login-form-box-header {
            padding: 18px 30px 10px;
            height: 40px;
            text-align: left;
            position: relative;
          }
        }
      }
      position: relative;
      width: 100%;
      margin-top: 29px;
    }
    .login-logo-conatiner {
    }
    width: 485px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 50px;
    top: 10%;
  }
  .login-page-bg {
    position: fixed;
    z-index: -6;
    bottom: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  overflow: auto;
  position: absolute;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  text-align: center;
}
</style>
